import * as React from "react"

import Layout from "../../components/Layout/Layout"
import MasonryGrid from "../../components/MasonryGrid/MasonryGrid"

import Seo from "../../components/seo"

import { graphql, useStaticQuery } from "gatsby"
import loadable from "@loadable/component"
import { enricheLotWithAuction, getAuctionsIds } from "../../utils/auction"

import * as styles from "./AllArtworks.module.scss"
import { useMemo } from "react"
import Page from "../../components/Page/Page"

const LoadableGalleryMonitor = loadable(
  () => import("../../components/GalleryMonitor/GalleryMonitor")
)

const AllArtworks = () => {
  const data = useStaticQuery(graphql`
    query {
      allContentfulSale {
        edges {
          node {
            createdAt
            endDate
            name
            slug
            mainMedia {
              gatsbyImageData
              title
            }
            description {
              description
            }
            startDate
            endDate
            lots {
              auctionId
              lotId
              additionalMedia {
                gatsbyImageData(placeholder: BLURRED)
                file {
                  contentType
                  url
                }
              }
              asset {
                ... on ContentfulNftAsset {
                  id
                  slug
                  title
                  artist {
                    profilePicture {
                      gatsbyImageData
                    }
                    description {
                      description
                    }
                    name
                    slug
                  }
                  mainMedia {
                    file {
                      contentType
                      url
                    }
                    gatsbyImageData
                  }
                  animationPreviewMedia {
                    file {
                      contentType
                      url
                    }
                    gatsbyImageData
                  }
                }
              }
            }
          }
        }
      }
    }
  `)

  const organizationId = useMemo(() => {
    return parseInt(process.env.GATSBY_MONEGRAPH_ORGANIZATION_ID as string)
  }, [])

  const auctions = useMemo(() => {
    return getAuctionsIds(data?.allContentfulSale?.edges[0].node.lots)
  }, [data])

  return (
    <Page>
      <Seo title={data?.allContentfulSale?.edges[0].node.name} />

      <div className={styles.allArtwork}>
        <h1>All Artworks</h1>
        <LoadableGalleryMonitor
          auctions={auctions}
          render={({ result }: any) => {
            const enrichedData = enricheLotWithAuction(
              data?.allContentfulSale?.edges[0].node.lots || [],
              result?.auctions
            )
            return <MasonryGrid col={4} data={enrichedData} showSort />
          }}
        />
      </div>
    </Page>
  )
}

export default AllArtworks
