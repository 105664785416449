import * as React from "react"
import { Helmet } from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"
import { useEffect } from "react"

interface SeoProps {
  description?: string
  lang?: string
  title: string
  meta?: Array<
    { name: string; content: string } | { property: string; content: string }
  >
  image?: string
  video?: string
  type?: string
}

function Seo({
  description = "",
  lang = "en",
  meta = [],
  title,
  image,
  video,
  type = "article",
}: SeoProps) {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            author
          }
        }
      }
    `
  )

  const defaultTitle = site.siteMetadata?.title

  useEffect(() => {
    // @ts-ignore
    if (window?.analytics && title) {
      // @ts-ignore
      window.analytics.page(title)
    }
  }, [title])

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={title}
      titleTemplate={defaultTitle ? `%s | ${defaultTitle}` : null}
    >
      <meta property="og:type" content={type} />
      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />
      {image && <meta property="og:image" content={image} />}
      {video && <meta property="og:video" content={video} />}

      <meta name="twitter:title" content={title} />

      <meta name="twitter:site" content="@artnet" />

      <meta name="twitter:card" content="summary_large_image" />

      {description && <meta name="twitter:description" content={description} />}

      {image && <meta name="twitter:image" content={image} />}

      <script type="text/javascript">{`
          adroll_adv_id = "TE75D57BRVHQRIKRXZW3ZQ";
          adroll_pix_id = "GH4Y62U3YNH6BII3ZUEAKK";
          adroll_version = "2.0";

          (function(w, d, e, o, a) {
          w.__adroll_loaded = true;
          w.adroll = w.adroll || [];
          w.adroll.f = [ 'setProperties', 'identify', 'track' ];
          var roundtripUrl = "https://s.adroll.com/j/" + adroll_adv_id
          + "/roundtrip.js";
          for (a = 0; a < w.adroll.f.length; a++) {
          w.adroll[w.adroll.f[a]] = w.adroll[w.adroll.f[a]] || (function(n) {
          return function() {
          w.adroll.push([ n, arguments ])
        }
        })(w.adroll.f[a])
        }

          e = d.createElement('script');
          o = d.getElementsByTagName('script')[0];
          e.async = 1;
          e.src = roundtripUrl;
          o.parentNode.insertBefore(e, o);
        })(window, document);
          adroll.track("pageView");
      `}</script>
    </Helmet>
  )
}

Seo.defaultProps = {
  lang: `en`,
  meta: [],
  description: ``,
}

export default Seo
